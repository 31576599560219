@import "../../assets/fonts/fonts.scss";
.maintenance {
  background: #212121;
  min-height: 100vh;
  .imagesWrapper {
    padding: 0 22px 30px;
    .watchidLogo {
      height: 40px;
    }
    .basicLogo {
      width: 85px;
      display: block;
      margin: 0 auto;
      padding-bottom: 40px;
    }
  }
  .maintenanceContent {
    z-index: 9;
    text-align: center;

    .header {
      font-size: 20px;
      line-height: 1em;
      text-transform: uppercase;
      font-family: $metablack;
      color: #fff;
      max-width: 180px;
      margin: 0 auto;
      padding-bottom: 30px;
    }
    .subheader {
      font-size: 16px;
      font-family: $helvetica;
      color: #fff;
      margin: 0 auto;
      padding-bottom: 150px;
      width: 90vw;
      .smallTitle{
        display: block;
        padding-bottom: 0.5vh;
      }
    }
    .yourWatch {
      opacity: 9%;
      height: 130px;
    }
  }
}
