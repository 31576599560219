@import "../../assets/fonts/fonts";
.overlay {
  display: none;
}
.overlayShown {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  left: 0;
  display: block;
  z-index: 10;
}

.navIcon {
  right: 5vw;
}
.navIconRTL {
  left: 5vw;
}
.navIconRTL,
.navIcon {
  position: absolute;
  top: 13px;
  z-index: 101;
  display: flex;
  flex-flow: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  .hamburger {
    width: 22px;
    display: block;
    height: 1px;
    background: white;
    transition: transform 600ms ease 0s, background 300ms ease 0s;
    &:nth-child(2) {
      margin: 6px 0px;
    }
  }
  .cross {
    width: 22px;
    display: block;
    height: 1px;
    background: rgb(0, 0, 0);
    transition: transform 600ms ease 0s, background 300ms ease 0s;
    &:nth-child(1) {
      transform: translateY(6px) rotate(45deg);
    }
    &:nth-child(2) {
      margin: 6px 0px;
      transform: scale(0, 0);
    }
    &:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}
.navigation2 {
  width: 50%;
  display: inline-block;
  padding: 0;
  margin: 0;
}
.navigation {
  display: inline-block;
  list-style-type: none;
  width: 50%;
  background-color: white;
  color: #000;
  padding: 0;
  position: fixed;
  margin-top: 0;
  height: 100vh;
  text-align: center;
  z-index: 100;

  .borderBottom {
    color: #be0100;
  }

  li {
    font-family: $helvetica;
    margin-bottom: 34px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    &:first-child {
      margin-top: 100px;
    }
    a {
      font-family: $helvetica;
      letter-spacing: 0.05em;
      font-weight: 600;
      text-decoration: none;
      color: #000;
      line-height: 1.4em;
    }
    img {
      height: 16px;
      display: flex;
      margin: 0 auto 8px auto;
    }
    &:last-of-type {
      bottom: 20%;
      position: absolute;
      width: 100%;
    }
  }
}

#slider {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vw;
  transform: translate3d(100vw, 0, 0);
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

#slider.slide-in {
  transform: translate3d(0vw, 0, 0);
}
#sliderRTL {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vw;
  transform: translate3d(-100vw, 0, 0);
  transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  .navigation {
    left: 0;
  }
}

#sliderRTL.slide-in {
  transform: translate3d(0vw, 0, 0);
}
