@import "../../assets/fonts/fonts";
.closeButton {
  position: absolute;
  top: 13px;
  right: 5vw;
  img {
    height: 16px;
    width: 16px;
  }
}

.languageSwitcherRTL {
  left: 5vw;
  right: unset;
}

.dropdownWrapper {
  overflow: scroll;
  transition: height 0.6s ease 0.15s, max-height 0.6s ease;
  height: 100%;
  max-height: 999px;
  width: 100%;
  z-index: 1005;
  background-color: #212121;
  position: fixed;
  &.hidden {
    max-height: 0;
    transition: height 0.6s ease 0.15s, max-height 0.6s ease;
  }
  .imagesWrapper {
    padding: 0 22px;
    .watchidLogo {
      height: 40px;
      padding-left: 5vw;
    }
    .watchidLogoRTL {
      height: 40px;
      padding-right: 5vw;
    }
    .basicLogo {
      width: 85px;
      display: block;
      margin: 0 auto;
      padding-bottom: 40px;
    }
  }
}
.textWrapper {
  font-weight: bold;
  text-align: center;
  font-size: 17px;
  line-height: 1.7em;
  text-transform: uppercase;
  font-family: $metablack;
  color: #fff;
  .selectedLanguage {
    color: #be0100;
  }
  .heading {
    margin-bottom: 20px;
  }
}
